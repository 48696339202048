'use strict';

angular.module('windmanagerApp')
  .config(function($stateProvider) {
    $stateProvider
      .state('main', {
        url: '/',
        template: '<main></main>',
        authenticate: true,
        data: {
          bodyClass: 'home'
        }
      });
  });
